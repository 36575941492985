<template>
  <div>
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('结算')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="search" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>

    <van-cell-group inset style="margin-bottom: 10px;margin-top:5px;">
     <van-cell is-link @click="ShowAddressSelect">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #value>
          <van-row>
            <van-tag type="danger">默认</van-tag>
            <van-tag type="success">公司</van-tag>
            <span class="address1">{{curexpressaddress.address1}}</span>
          </van-row>
          <van-row>
            <span class="address">{{curexpressaddress.address2}}</span>
          </van-row>
          <van-row>
            <span class="address1">{{curexpressaddress.name}} {{curexpressaddress.tel}}</span>
          </van-row>
        </template>        
      </van-cell>
    </van-cell-group>
    <van-cell-group inset style="margin-bottom: 10px;margin-top:5px;" title="">
     <van-cell title="购买商品信息"></van-cell>
     <van-cell is-link>
        <!-- 使用 title 插槽来自定义标题 -->
        <template #value>
          <van-row>
            <van-col span="16">
              <div class="orderimglist">
                <van-image 
                  v-for="(item) in goods"
                  :key="item.id"
                  :name="item.id"
                  width="80"
                  height="80"
                  :src="item.imageurl"
                />
              </div>
            </van-col>
            <van-col span="6">
              <span>{{ '共' + goods.length + '件'}}</span>
            </van-col>
          </van-row>
        </template>        
      </van-cell>
      <van-cell title="配送信息" :value="expressName" is-link @click="ShowExpress" />
      <van-field v-model="beizhu" label="备注留言" placeholder="建议事先沟通一下，防止疏漏" />
    </van-cell-group>
        <van-popup
      v-model:show="isShowAddress"
      round
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <van-row>
        <div class="poptitle">收货地址</div>
      </van-row>
      <van-row>
        <van-address-list
          v-model="chosenAddressId"
          :list="addresslist"
          :disabled-list="disabledList"
          disabled-text="以下地址超出配送范围"
          default-tag-text="默认"
          @add="onAddAddress"
          @click-item="SelectAddress"
          @edit="onEditAddress"
        />
      </van-row>
    </van-popup>
    <van-popup
      v-model:show="isShowExpress"
      round
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <van-row>
        <div class="poptitle">配送</div>
      </van-row>
      <van-row>
        <van-tabs v-model:active="expressMode" style="width:100%">
          <van-tab v-for="ex in expressList" :key="'a' + ex.expressid">
            <template #title> {{ex.expressname}} </template>
            <van-row>
              <span class="expresstip">{{ex.expresstip}}</span>
            </van-row>
            <van-row>
              <p class="expresstime">送货时间:</p>
              <p class="expresstime">{{ ex.expresstime }}</p>
            </van-row>
            
          </van-tab>
        </van-tabs>
        <van-button round type="danger" block @click="isShowExpress = false">确定</van-button>
      </van-row>
    </van-popup>
   

    <van-cell-group center inset style="margin-bottom: 10px;margin-top: 10px;">
      <van-cell title="支付方式" :value="paymodeName" />
      <van-radio-group v-model="paymode" direction="horizontal" style="margin-left:9px;margin-top: 2px;">
        <van-radio name="1" style="height:70px;">
          微信
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('/assets/images/wxpayactive.png') : require('/assets/images/wxpayinactive.png')" />
          </template>
        </van-radio>
        <van-radio name="2">
          校园卡
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('/assets/images/ecardactive.png') : require('/assets/images/ecardinactive.png')" />
          </template>
        </van-radio>
        <van-radio name="3">
          储值卡
          <template #icon="props">
            <img class="img-icon" :src="props.checked ? require('/assets/images/savecardactive.png') : require('/assets/images/savecardinactive.png')" />
          </template>
        </van-radio>
        <van-cell></van-cell>
      </van-radio-group>
    </van-cell-group>
    <van-cell-group inset style="margin-bottom: 90px;">
      <van-cell title="商品金额" :value="formatPrice(totalPrice)" />
      <van-cell title="运费" value="0.00" />
      <van-cell title="立减" value="0.00" />
      <van-cell title="优惠" value="0.00" />
      <van-cell :value="'合计：' + formatPrice(totalPrice)" />
    </van-cell-group>  
    <van-submit-bar
      :price="totalPrice * 100"
      :button-text="submitBarText"
      @submit="onSubmit"
      class="van-submit-bar-order"
    >
      <template #tip>
        你的收货地址不支持配送,11111 <span @click="onClickLink">修改地址</span>
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { AddressList, Tabs, Tab, Image, Button, Popup, Radio, RadioGroup, Field, Cell, Row, Col, Tag, Checkbox, CellGroup, Card, SubmitBar, Toast, Sticky, NavBar, Icon, Stepper } from 'vant';

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CellGroup.name]: CellGroup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Stepper.name]: Stepper,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Field.name]: Field,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [AddressList.name] : AddressList 
  },

  data() {
    return {
      peisong: '',
      beizhu: '',
      curexpressaddress: {},
      chosenAddressId: 0,
      isShowAddress: false,
      expressName: '',
      expressList: [
        {expressid: '0', expressname: '快递',expresstime: '工作日双休日与节假日均可送货', expresstip: '若社区村镇无人员管控，可送货上门'},
        {expressid: '1', expressname: '自提',expresstime: '工作日双休日与节假日均可提货', expresstip: '疫情期间，请注意防护'},
        {expressid: '2', expressname: '快递柜',expresstime: '工作日双休日与节假日均可取货', expresstip: '疫情期间，请注意防护，柜子已消毒'}
      ],
      expressMode: '0',
      isShowExpress: false,
      paymode: 0,
      paymodeName: '',
      paymodelist: ['默认支付', '微信支付', '校园卡支付', '储值卡支付', '支付宝支付', '银行卡支付'],
      activeIcon: 'https://cdn.jsdelivr.net/npm/@vant/assets/user-active.png',
      inactiveIcon: 'https://cdn.jsdelivr.net/npm/@vant/assets/user-inactive.png',
      goods: [],
      addresslist: [],
      disabledList: []
    };
  },
  watch: {
    paymode (val) {
      console.log(val);
      this.paymodeName = this.paymodelist[val];
    },
    expressMode (val) {
      console.log(val)
      this.expressList.forEach(ex => {
        if (ex.expressid === val.toString()) {
          this.expressName = ex.expressname;
          return;
        }
      });
      console.log(this.expressName)
    }
  },
  computed: {
    submitBarText() {
      const count = this.goods.length;
      return '支付' + (count ? `(${count})` : '');
    },
    totalPrice() {
      console.log('开始计算总价')
      // console.log(this.goods)
      // console.log(this.goods.reduce((total, item) => total + item.price * item.num , 0))
      return this.goods.reduce((total, item) => total + item.price * item.num , 0);
    }
  },
  mounted() {
    console.log('进入结算界面')
    this.addresslist = require('/src/data/addresslist.json')
    this.goods = this.$store.state.cartlist;
    console.log(this.goods)
    if (this.goods === undefined ||this.goods.length === 0 ){
      Toast('结算信息为空，请重新进入购物车')
      // this.$router.push('cart');
      this.$router.push({ path: 'home?' + new Date() });

    }
    if (this.addresslist.length>0) this.curexpressaddress = this.addresslist[0]
  },
  methods: {
    SelectAddress(item, index) {
      Toast(index+ '选中地址' + item.address)
      this.isShowAddress = false
      this.curexpressaddress = item
    },
    onAddAddress() {
      Toast('开始增加新地址')
    },
    onEditAddress(item, index) {
      console.log(item, index)
      Toast('开始编辑地址' + item.address)
    },
    ShowAddressSelect() {
      this.addresslist = require('/src/data/addresslist.json')
      this.isShowAddress = true
    },
    ShowExpress() {
      this.isShowExpress = true;
    },
    formatPrice(price) {
      return (price / 1).toFixed(2);
    },

    onSubmit() {
      Toast('点击结算');
      this.$router.push('order');
    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;


  &__item {
    position: relative;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
.address {
    color: #000000;
    margin: 5px;
    font-size: 15px;
    font-weight: bold;
    line-height: 140%;
    align-items: left;
}
.address1 {
    color: #000000;
    margin: 5px;
    font-size: 13px;
    line-height: 140%;
    align-items: left;
}
.van-card {
  background: #ffffff
}
.orderimglist {
  height:80px;
  width:250px;
  margin-right: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  object-fit: fit;
  &::-webkit-scrollbar {
    display: none;
  }
}
.van-cell__value--alone {
    color: var(--van-text-color);
    text-align: right;
    color:#f44;
}
.img-icon {
  height: 25px;
}
.poptitle {
  margin-left: 15px;
  margin-top: 15px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-family:"微软雅黑",Georgia,Serif;
}
.expresstip {
  margin-left: 15px;
  margin-top: 15px;
  color: #000000;
  font-size: 9px;
  font-family:"微软雅黑",Georgia,Serif;
}
.expresstime {
  margin-left: 15px;
  margin-top: 15px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  font-family:"微软雅黑",Georgia,Serif;
}
.van-submit-bar-order {
  bottom: 0px;
}
</style>
